<!--
 * @Descripttion: 高德地图
 * @version: 1.0.0
 * @@Company: ZZCYI
 * @Author: 刘
 * @Date: 2020-08-03 15:39:14
 * @LastEditors: 王
 * @LastEditTime: 2021-01-07 15:24:11
 * @Update Descripttion:
-->
<template>
  <div class="amap-wrapper">
    <el-amap-search-box
      class="search-box"
      :search-option="searchOption"
      :on-search-result="onSearchResult"
      v-if="show"
    />
    <el-amap
      class="amap-box"
      :style="{ 'width': width, 'height': height }"
      :vid="'amap-vue-search'"
      ref="map"
      :center="center"
      :zoom="zoom"
      :events="events"
    >
      <el-amap-marker
        v-for="(marker, index) in markers"
        :position="marker"
        :key="index"
      ></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
export default {
  name: 'MapG',
  props: {
    width: {
      type: String || Number,
      default: '90%'
    },
    height: {
      type: String || Number,
      default: '400px'
    }
  },
  data () {
    return {
      zoom: 12,
      center: [116.396304, 39.909847],
      show: true,
      events: {
        'click': (e) => {
          const that = this
          if (!e) {
            return
          }
          const positionArr = []
          positionArr.push(e.lnglat.lng)
          positionArr.push(e.lnglat.lat)
          this.markers = []
          this.markers.push(positionArr)
          // 这里通过高德 SDK 完成。
          // eslint-disable-next-line no-undef
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          })
          geocoder.getAddress(positionArr, function (status, result) {
            console.log(result, 'resultresultresult')
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                // let num = result.regeocode.formattedAddress
                // let indexs = result.regeocode.addressComponent.province.length
                // let index = num.indexOf('市') + 1
                // let city = num.substring(indexs, index)
                // if (city) {
                const detailObj = {
                  info: result.regeocode.addressComponent,
                  detailedAddress: result.regeocode.formattedAddress,
                  city: result.regeocode.addressComponent.city,
                  lng: positionArr[0],
                  lat: positionArr[1]
                }
                console.log(detailObj, 'detailObjdetailObj')
                that.$emit('getInfo', detailObj)
                // }
              }
            }
          })
        }
      },
      markers: [],
      searchValue: '',
      searchOption: {
        city: '',
        citylimit: false
      }
    }
  },
  methods: {
    init (lng, lat, show) {
      console.log(show, 'showssss')
      this.show = show
      console.log(this.searchValue, 'searchValue')
      this.searchValue = ''
      if (lng && lat) {
        this.center = [lng, lat]
        this.markers = [this.center]
        this.$forceUpdate()
      } else {
        this.center = [116.396304, 39.909847]
      }
      this.$forceUpdate()
    },
    onSearchResult (pois) {
      console.log(pois, this.searchValue, 'poispoispois')
      let latSum = 0
      let lngSum = 0
      this.markers = []
      if (pois.length > 0) {
        pois.forEach((poi, index) => {
          if (index === 0) {
            const { lng, lat } = poi
            lngSum += lng
            latSum += lat
            this.markers.push([poi.lng, poi.lat])
          }
          console.log(this.markers[0][0], ' this.markers this.markers')
        })
        // const center = {
        //   lng: lngSum / pois[0],
        //   lat: latSum / pois[0]
        // }
        this.center = [this.markers[0][0], this.markers[0][1]]
        const num = pois[0]
        console.log(num, '滴滴滴滴滴滴')
        const query = {
          address: num.name + num.address,
          lat: num.lat,
          log: num.lng

        }
        this.$emit('changeAddress', query)
      }
    }
  }
}
</script>
<style scoped>
.amap-wrapper {
  text-align: right;
  position: relative;
}

.amap-box {
  width: 90%;
  margin-left: 5%;
}

.search-box {
  position: absolute;
  top: 15px;
  left: 6%;
}
</style>
